import { FromSchema } from "json-schema-to-ts";

import * as gatewayConfigRawSchema from "./config.schema.json";

type GatewayConfigRaw = {
  $schema: "http://json-schema.org/draft-07/schema#";
  type: "object";
  properties: {
    server: {
      type: "object";
      additionalProperties: false;
      properties: {
        port: { $ref: "#/definitions/port" };
        host: { type: "string" };
        write_timeout: { $ref: "#/definitions/timestring" };
        read_timeout: { $ref: "#/definitions/timestring" };
        shutdown_delay: { $ref: "#/definitions/timestring" };
        sentry_environment: { type: "string"; pattern: "production|staging|development" };
        traces_sample_rate: { type: "number"; minimum: 0; maximum: 1 };
        profiles_sample_rate: { type: "number"; minimum: 0; maximum: 1 };
      };
      required: ["port", "host"];
    };
    PPROF: {
      type: "object";
      additionalProperties: false;
      properties: {
        enabled: { type: "boolean" };
        port: { $ref: "#/definitions/port" };
        host: { type: "string" };
      };
    };
    proxy: { $ref: "#/definitions/proxy" };
    auth_service: {
      type: "object";
      additionalProperties: false;
      properties: {
        api_key: { type: "string" };
        base_url: { type: "string" };
        request_timeout: { $ref: "#/definitions/timestring" };
        sync_interval: { $ref: "#/definitions/timestring" };
      };
      required: ["base_url", "api_key"];
    };
    redis: {
      type: "object";
      additionalProperties: false;
      properties: {
        url: { type: "string" };
      };
    };
    override: {
      type: "object";
      additionalProperties: false;
      properties: {
        plans: {
          type: "object";
          patternProperties: {
            "internal|free|enterprise|startup|pro|business": {
              $ref: "#/definitions/overrideEntity";
              required: ["subscriptionCallLimit"];
            };
          };
        };
        organizations: {
          type: "object";
          patternProperties: {
            "[1-9]+[0-9]*": {
              $ref: "#/definitions/overrideEntity";
            };
          };
        };
      };
      anyOf: [{ required: ["plans"] }, { required: ["organizations"] }];
    };
    fallback: {
      type: "object";
      additionalProperties: false;
      properties: {
        realtime: { $ref: "#/definitions/realtimeLimit" };
        limits: { $ref: "#/definitions/periodicalLimits" };
        ws: { $ref: "#/definitions/wsLimit" };
        subscriptionCallLimit: { type: "integer" };
      };
      required: ["realtime", "subscriptionCallLimit"];
    };
    metrics: {
      type: "object";
      additionalProperties: false;
      properties: {
        port: { type: "integer" };
        read_timeout: { type: "string"; pattern: "^\\d+[smh]$" };
        write_timeout: { type: "string"; pattern: "^\\d+[smh]$" };
      };
      required: ["port"];
    };
    internal_services: {
      type: "object";
      additionalProperties: false;
      properties: {
        tx_gateway_url: { type: "string" };
      };
    };
    web3_request_timeout_by_method: {
      type: "object";
      patternProperties: {
        ".*": { $ref: "#/definitions/timestring" };
      };
      required: ["default"];
    };
  };
  required: ["server", "metrics", "proxy", "override", "web3_request_timeout_by_method"];
  definitions: {
    port: {
      type: "integer";
      minimum: 1;
      maximum: 65535;
    };
    timestring: {
      type: "string";
      pattern: "^\\d+[smh]$";
    };
    proxy: {
      type: "array";
      items: {
        type: "object";
        additionalProperties: false;
        properties: {
          path: { type: "string" };
          rewrite: {
            type: "array";
            items: { type: "string" };
          };
          target: {
            type: "object";
            properties: {
              type: { type: "string"; enum: ["url", "nats"] };
            };
            required: ["type"];
            if: {
              properties: {
                type: { const: "url" };
              };
            };
            then: {
              properties: {
                url: { type: "string" };
              };
              required: ["url"];
            };
          };
          restrict: { type: "boolean" };
          auth: {
            type: "object";
            description: "If the auth object is present, the request will be authenticated using the provided credentials";
            additionalProperties: false;
            properties: {
              type: {
                type: "string";
                enum: ["header", "query", "url"];
              };
              key: { type: "string" };
              value: { type: "string" };
            };
            anyOf: [
              {
                if: {
                  properties: {
                    type: { const: "header" };
                  };
                };
                then: {
                  required: ["key", "value"];
                };
              },
              {
                if: {
                  properties: {
                    type: { const: "query" };
                  };
                };
                then: {
                  required: ["key", "value"];
                };
              },
              {
                if: {
                  properties: {
                    type: { const: "url" };
                  };
                };
                then: {
                  required: ["value"];
                };
              }
            ];
          };
        };
        allOf: [
          {
            if: {
              allOf: [
                {
                  not: {
                    properties: {
                      auth: { type: "null" };
                    };
                  };
                },
                {
                  properties: {
                    auth: {
                      properties: {
                        type: { const: "url" };
                      };
                    };
                  };
                }
              ];
            };
            then: {
              properties: {
                target: {
                  type: "object";
                  properties: {
                    type: { const: "url" };
                    url: { type: "string"; pattern: "{auth}" };
                  };
                };
              };
            };
          },
          {
            anyOf: [
              {
                if: {
                  properties: {
                    restrict: { const: false };
                  };
                };
                then: {
                  required: ["path", "target"];
                };
              },
              {
                if: {
                  properties: {
                    restrict: { const: true };
                  };
                };
                then: {
                  required: ["path"];
                };
              }
            ];
          }
        ];
      };
    };
    realtimeLimit: {
      type: "object";
      additionalProperties: false;
      properties: {
        calls: { type: "integer" };
        window: { type: "string"; pattern: "^\\d+[smh]$" };
      };
      required: ["calls", "window"];
    };
    periodicalLimits: {
      type: "array";
      items: {
        type: "object";
        additionalProperties: false;
        properties: {
          window: { type: "string"; pattern: "^\\d+[smh]$" };
          calls: { type: "integer" };
        };
        required: ["window", "calls"];
      };
    };
    wsLimit: {
      type: "object";
      additionalProperties: false;
      properties: {
        realtime: {
          type: "object";
          additionalProperties: false;
          properties: {
            calls: { type: "integer" };
            window: { type: "string"; pattern: "^\\d+[smh]$" };
          };
          required: ["calls", "window"];
        };
        connections_limit: { type: "integer" };
      };
    };
    overrideEntity: {
      type: "object";
      properties: {
        proxy: {
          $ref: "#/definitions/proxy";
        };
        limits: {
          type: "array";
          items: {
            type: "object";
            additionalProperties: false;
            properties: {
              pattern: { type: "string" };
              realtime: { $ref: "#/definitions/realtimeLimit" };
              limits: { $ref: "#/definitions/periodicalLimits" };
              subscriptionCallLimit: { type: "integer" };
            };
            required: ["pattern", "realtime"];
          };
        };
        ws: { $ref: "#/definitions/wsLimit" };
      };
      anyOf: [{ required: ["proxy"] }, { required: ["limits"] }, { required: ["ws"] }];
    };
  };
};

export const gatewayConfigSchema = gatewayConfigRawSchema as GatewayConfigRaw;

export type OrgConfigSchema = typeof gatewayConfigSchema.definitions.overrideEntity;

export const orgConfig = {
  type: "object",
  $ref: gatewayConfigSchema.properties.override.properties.organizations.patternProperties["[1-9]+[0-9]*"].$ref,
  definitions: gatewayConfigSchema.definitions
} as unknown as OrgConfigSchema;

export type GatewayConfig = FromSchema<typeof gatewayConfigSchema>;

type OrganizationsType = NonNullable<GatewayConfig["override"]["organizations"]>;
export type OrgConfig = OrganizationsType[string];
