import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AuthService } from "../auth/auth.service";

export const consentGiven: CanActivateFn = async () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const isLoggedIn = await firstValueFrom(authService.getLoggedInSubject());

  if (!isLoggedIn) {
    return router.parseUrl(`/login?redirectUrl=${window.location.pathname}`);
  }

  const user = await firstValueFrom(authService.getUser());

  if (!user) {
    return router.parseUrl(`/login?redirectUrl=${window.location.pathname}`);
  }

  if (user.consentTypes.length === 0) {
    return router.parseUrl("/profile-setup");
  }

  return true;
};
